angular
    .module("smartermail")
    .controller("onlyOfficeController", onlyOfficeController);

function onlyOfficeController($timeout, $scope, $translate, $q, $mdDialog, $http, meetingWorkspaces, coreDataCalendar, coreDataFileStorage, errorHandling,
	userTimeService, coreData, coreDataSettings, claimsService, themesService, localeInfoService, type, authStorage) {
    var vm = this;

	vm.extension; 

    vm.type = type;
    vm.allFolders = [];

    // Available document types
    vm.availableDocumentTypes = [
        { id: 0, desc: $translate.instant("DOCUMENT") },
        { id: 1, desc: $translate.instant("DOCUMENT_PRESENTATION") },
        { id: 2, desc: $translate.instant("DOCUMENT_SPREADSHEET") }
    ];
	
    vm.details = {
        id: null,
        title: null,
        availableDocumentTypes: vm.type,
        selectedFolder: null
    };

    vm.isValid = true;

    // Functions
    vm.cancel = cancel;
    vm.save = save;
    vm.getFolders = getFolders;

    activate();

    function activate() {
        getFolders();
    }

    function getFolders() {
        $http.get("~/api/v1/filestorage/folders")
            .then(function (response) {
                vm.allFolders = getAllFolders(response.data.folder.subFolders);
            })
            .catch(function (error) {
                console.error('Error fetching folders:', error);
                errorHandling.error('Unable to retrieve folders. Please try again later.');
            });
    }

    function getAllFolders(folders) {
        var allFolders = [];
        folders.forEach(function (folder) {
            if (folder.name !== "ONLINE_MEETING_FILES") {
                allFolders.push(folder);
                if (folder.subFolders && folder.subFolders.length > 0) {
                    var subFolders = getAllFolders(folder.subFolders);
                    allFolders = allFolders.concat(subFolders);
                }
            }
        });
        if (allFolders.length === 0) {
            allFolders.push({ path: '/' });
        }
        return allFolders;
    }
	function generateRandom12DigitNumber() {
		const MIN = 100000000000;
		const MAX = 999999999999;
		return String(Math.floor(Math.random() * (MAX - MIN + 1)) + MIN);
	}
	const FILE_JSON = {
		"fileactions": [
			{
				"type": "pdf",
				"actions": ["view"],
				"convert": ["bmp", "gif", "jpg", "pdf", "pdfa", "png"]
			},
			{
				"type": "word",
				"actions": ["view", "auto-convert"],
				"convert": ["docx", "docxf", "bmp", "docm", "dotm", "dotx", "epub", "fb2", "gif", "html", "jpg", "odt", "ott", "pdf", "pdfa", "png", "rtf", "txt"]
			},
			{
				"type": "word",
				"actions": ["view", "edit"],
				"convert": ["docx", "docxf", "bmp", "dotm", "dotx", "epub", "fb2", "gif", "html", "jpg", "odt", "ott", "pdf", "pdfa", "png", "rtf", "txt", "doc"]
			},
			{
				"type": "cell",
				"actions": ["view", "lossy-edit"],
				"convert": ["xlsx", "bmp", "gif", "jpg", "ods", "ots", "pdf", "pdfa", "png", "xlsm", "xltm", "xltx"]
			},
			{
				"type": "cell",
				"actions": ["view", "auto-convert"],
				"convert": ["xlsx", "bmp", "csv", "gif", "jpg", "ods", "ots", "pdf", "pdfa", "png", "xlsm", "xltm", "xltx"]
			},
			{
				"type": "slide",
				"actions": ["view", "auto-convert"],
				"convert": ["pptx", "bmp", "gif", "jpg", "odp", "otp", "pdf", "pdfa", "png", "potm", "potx", "ppsm", "ppsx", "pptm"]
			},
			{
				"type": "slide",
				"actions": ["view", "lossy-edit", "auto-convert"],
				"convert": ["pptx", "bmp", "gif", "jpg", "otp", "pdf", "pdfa", "png", "potm", "potx", "ppsm", "ppsx", "pptm"]
			},
			{
				"type": "slide",
				"actions": ["view", "edit"],
				"convert": ["pptx", "bmp", "gif", "jpg", "odp", "otp", "pdf", "pdfa", "png", "potm", "potx", "ppsm", "ppsx", "pptm"]
			}
		]
	}



	async function getDocumentStatus(file) {
		return $http.post("~/api/v1/settings/GetDocumentStatus", JSON.stringify(file), { headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				if (response.data && response.data.statuses) {
					return response.data.statuses;
				} else {
					throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
				}
			})
			.catch(error => {
				return "Unknown";
			});
	}

	function documentOptions(args, fileid) {
		$mdDialog.show({
			controller: "documentStatusDlgController",
			templateUrl: "app/file-storage/modals/document-status.dlg.html",
			targetEvent: args.event,
			locals: {
				selectedCards: args.card.selectedCards,
				fileid: fileid // Passing fileid to the controller
			},
			clickOutsideToClose: true
		}).then(function (confirm) {
			if (confirm && confirm.failure) {
				errorHandling.report(confirm.failure);
			}
		});
	}

	async function checkLimits(args) {
		try {
			let response = await $http.get("~/api/v1/settings/onlyoffice-limits");
			if (response.data && response.data.message) {
				var limits = response.data.message;
				if (limits == "0") {
					return 0;
				} else if (limits == "1") {
					errorHandling.report($translate.instant("MAX_ONLYOFFICE_CONNECTIONS_REACHED_DOMAIN"));
					return 1;
				} else if (limits == "2") {
					errorHandling.report($translate.instant("MAX_ONLYOFFICE_CONNECTIONS_REACHED_USER"));
					return 2;
				}
			} else {
				throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
			}
		} catch (error) {
			console.error("Error obtaining OnlyOffice limits:", error);
			throw error;
		}
	}





	async function onlyOfficeEdit(args) {
		try {
			var domain = authStorage.getUserEmail().split('@')[1];
			var file = args;
			var documentstatus = await getDocumentStatus(file.id);
			var limit = await checkLimits(args);
			var actions = getOnlyOfficeActionsByFileType(file.type);
			var mode;
			if (actions == 1 || actions == 3) {
				mode = "edit";
			} else {
				mode = "view";
			}
			if (documentstatus === "Open") {
				documentOptions(args, file.id);
			} else {
				if (limit !== 0) {
					throw new Error("OnlyOffice limit reached");
				} else {
					var downloadLink = `${window.location.href.substring(0, window.location.href.indexOf("/interface/root#"))}/api/v1/filestorage/${file.id}/download?token=${authStorage.getToken()}`;
					var docdata = searchByFileExtension(file.type);
					var dict = {
						"filetype": file.type,
						"key": generateRandom12DigitNumber(),
						"title": file.fileName,
						"fileName": file.fileName,
						"url": downloadLink,
						"doctype": String(docdata[0].type),
						"callbackUrl": `${window.location.origin}/api/v1/settings/onlyoffice-callback/${domain}/${file.id}`,
						"lang": localeInfoService.language,
						"mode": mode,
						"name": authStorage.getUserEmail,
					};

					let response = await $http.post("~/api/v1/settings/onlyoffice-jwt", dict);
					var jwtToken = response.data.message;
					initDocEditor(jwtToken, file, downloadLink);
				}
			}
		} catch (error) {
			console.error("Error in onlyOfficeEdit:", error);
		}
	}


	function searchByFileExtension(fileExtension) {
		return FILE_JSON.fileactions.filter(item => item.convert.includes(fileExtension));
	}



	function getOnlyOfficeURL() {
		return $http.get("~/api/v1/settings/onlyoffice-url")
			.then(response => {
				if (response.data && response.data.message) {
					return response.data.message;
				} else {
					throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
				}
			})
			.catch(error => {
				console.error("Error obtaining OnlyOffice URL:", error);
				throw error;
			});
	}

	async function initDocEditor(jwtToken, file, downloadLink) {
		var onlyofficeUrl = await getOnlyOfficeURL();
		var doctype = String(searchByFileExtension(file.type)[0].type);

		var newWindow = window.open(coreData.baseUrl + '/interface/onlyoffice-editor');
		vm.maxEditorRetries = 100;
		var actions = getOnlyOfficeActionsByFileType(file.type);
		var mode;
		if (actions == 1 || actions == 3) {
			mode = "edit";
		} else {
			mode = "view";
		}

		var initobject = { newWindow, onlyofficeUrl, jwtToken, file, email: authStorage.getUserEmail(), downloadLink, doctype, language: localeInfoService.language, mode };
		doinit(initobject);



	}
	function doinit(initobject) {
		if (initobject.newWindow && initobject.newWindow.initEditor) {
			initobject.newWindow.initEditor(initobject);
			initobject.newWindow.focus();
		} else {
			vm.maxEditorRetries--;
			if (vm.maxEditorRetries > 0) {
				setTimeout(() => doinit(initobject, 100));
			} else {
				initobject.newWindow.close();
			}
		}

	}

	function getOnlyOfficeActionsByFileType(fileExtension) {
		// zero means that it cannot be edited with onlyoffice.
		// 1 means that it can be edited.
		// 2 means that it can only be viewed
		// 3 means that it can be viewed and edited.
		// it should get the actions from the FILE_JSON

		let action = 0;
		//return FILE_JSON.fileactions.filter(item => item.convert.includes(fileExtension));

		const fileActions = FILE_JSON.fileactions.filter(item => item.convert.includes(fileExtension));

		if (fileActions.length > 0) {
			const actions = fileActions.flatMap(file => file.actions);
			const canView = actions.includes("view");
			const canEdit = actions.includes("edit");
			const canAutoConvert = actions.includes("auto-convert");
			const canLossyEdit = actions.includes("lossy-edit");

			if (canView && canEdit) {
				action = 3;
			} else if (canEdit || canLossyEdit) {
				action = 1;
			} else if (canView) {
				action = 2;
			}
		}

		return action;
	}


	function save() {
        var language = localeInfoService.language;
        vm.isValid = true;

        if (!vm.details.title) {
            vm.isValid = false;
        }

		if (vm.isValid) {
			var documentType = vm.details.availableDocumentTypes;
			if (documentType == "0") {
				vm.extension = ".docx";
			} else if (documentType == "1") {
				vm.extension = ".pptx";
			} else if (documentType == "2") {
				vm.extension = ".xlsx";
			}
		

            var folder = vm.allFolders.find(function (f) {
                return f.path === vm.details.selectedFolder;
            });
            var document = {
                language: language,
                filetype: documentType,
                folder: folder ? vm.details.selectedFolder : '',
                fn: vm.details.title
            };

            $http.post("~/api/v1/filestorage/create-document-from-template", document, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    const existingcallback = coreDataFileStorage.addFilesCallback;
                    coreDataFileStorage.addFilesCallback = (files) => {
                        console.log("Intercepted the add files ", files);
                        if (typeof existingcallback === "function") {
                            existingcallback(files);
						}
						
						if (files[0].fileName == vm.details.title + vm.extension) {
                            coreDataFileStorage.addFilesCallback = existingcallback;
							console.log("Match");

                            // Call the onlyOfficeEdit function from fileStorageController
                            onlyOfficeEdit(files[0]);
                            $mdDialog.hide(true);
                        }
                    };
                }, function (error) {
                    console.error('Error saving document:', error);
                    errorHandling.error(error);
                    $mdDialog.hide(false);
                });
        }
    }

    function cancel() {
        $mdDialog.hide();
    }
}
